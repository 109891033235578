import React, { FC } from "react";
import useSWR from "swr";
import { Box, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Field } from "react-final-form";
import TexEditor from "../../../components/TextEditor";
import Photo from "../../../components/Photo/Photo";
import { useFetcher } from "../../../hooks/useApi";
import { Tags } from "../Post.types";

type PostItemFieldsProps = {
  description: string;
};
const filter = createFilterOptions<FilmOptionType>();

interface FilmOptionType {
  inputValue: string;
  name: string;
}

const PostItemFields: FC<PostItemFieldsProps> = ({ description }) => {
  const fetcher = useFetcher();

  const { data: allTags } = useSWR("/tags", fetcher);

  const optionsItem =
    allTags?.length > 0
      ? allTags.map((elem: Tags) => {
          return { name: elem.tag };
        })
      : [];

  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Field subscription={{ value: true }} name="data.seo_title">
          {({ input }) => (
            <TextField
              {...input}
              variant="standard"
              sx={{ marginRight: "40px" }}
              fullWidth
              label="Seo name"
            />
          )}
        </Field>
        <Field subscription={{ value: true }} name="data.seo_description">
          {({ input }) => (
            <TextField
              {...input}
              variant="standard"
              fullWidth
              label="Seo description"
            />
          )}
        </Field>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.file">
          {({ input }) => (
            <Photo onChange={input.onChange} imageToken={input.value.token} />
          )}
        </Field>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.title">
          {({ input }) => (
            <TextField {...input} variant="standard" fullWidth label="Title" />
          )}
        </Field>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.tags">
          {({ input }) => (
            <Autocomplete
              id="tags"
              multiple
              {...input}
              onChange={(event, newValue: any) => {
                if (typeof newValue === "string") {
                  input.onChange({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  input.onChange({
                    name: newValue.inputValue,
                  });
                } else {
                  input.onChange(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={optionsItem}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Blog rubric" />
              )}
            />
          )}
        </Field>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Field subscription={{ value: true }} name="data.description">
          {({ input }) => <TexEditor {...input} value={description || ""} />}
        </Field>
      </Box>
    </Box>
  );
};

export default PostItemFields;
