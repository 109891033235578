import React, { FC, useMemo } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ThreadItemType } from "../SupportChatType";
import useCommon from "../../../hooks/useCommon";

type MessageHeaderProps = {
  data: ThreadItemType | null;
};

const ChatHeader: FC<MessageHeaderProps> = ({ data }) => {
  const { profile } = useCommon();
  const user = useMemo(() => {
    if (data) {
      return data.all_users.filter((item) => item.id !== profile?.id)[0];
    }
  }, [data, profile?.id]);

  return (
    <Box
      sx={(theme) => ({
        minHeight: "50px",
        display: "flex",
        alignItems: "center",
        p: "0 20px",
        borderBottom: `1px solid ${theme.palette.action.hover}`,
      })}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Link to={`/users/${user?.id}`}>
            <Avatar
              sx={{ width: "30px", height: "30px" }}
              src={`${process.env.REACT_APP_IMAGE_URL}${user?.avatar?.additional_info?.size_urls?.avatar_icon}`}
            />
          </Link>
        </Box>
        <Typography sx={{ marginLeft: "10px" }} variant="caption">
          {user?.full_name} {data?.is_active === false && "(Deleted)"}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatHeader;
