import React from "react";
import { Avatar, Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useCommon from "../../hooks/useCommon";

const AdminInfo = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { profile, setAuth, setToken } = useCommon();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    navigate(`/users/${profile?.id}`);
    handleClose();
  };

  const logOut = () => {
    setAuth?.(false);
    setToken?.("");
    localStorage.removeItem("dashboard-token");
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button onClick={handleClick}>
        <Avatar
          src={
            profile?.avatar === null
              ? ""
              : `${process.env.REACT_APP_IMAGE_URL}${profile?.avatar?.additional_info?.size_urls?.avatar_icon}`
          }
        />
      </Button>
      <Typography variant="body1">{profile?.full_name}</Typography>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem onClick={handleOpenProfile}>Profile</MenuItem>
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default AdminInfo;
