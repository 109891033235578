import React, { FC, useMemo } from "react";
import { Avatar, Badge, Box, Paper, Tooltip, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { Link, useParams } from "react-router-dom";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { ThreadItemType } from "../SupportChatType";
import useCommon from "../../../hooks/useCommon";
type ThreadItemProps = {
  data: ThreadItemType;
};

const ThreadItem: FC<ThreadItemProps> = ({ data }) => {
  const { profile } = useCommon();
  const { id } = useParams();
  const user = useMemo(() => {
    return data.users.filter((item) => item.id !== profile?.id)[0];
  }, [data.users, profile?.id]);

  return (
    <Paper
      component={Link}
      to={`/chat_support/${data.id}`}
      elevation={`${data.id}` === id ? 1 : 0}
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        padding: "20px",
        justifyContent: "space-between",
        cursor: "pointer",
        transition: "all 0.3s",
        border: `1px solid ${theme.palette.action.hover}`,
        "&:hover": {
          background: theme.palette.action.hover,
        },
        mb: "10px",
        textDecoration: "none",
      })}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Avatar
            src={`${process.env.REACT_APP_IMAGE_URL}${user?.avatar?.additional_info?.size_urls?.avatar_icon}`}
          />
        </Box>
        <Box
          sx={{
            marginLeft: "20px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "200px",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={user?.full_name}>
            <Typography noWrap variant="body1">
              {user?.full_name}
            </Typography>
          </Tooltip>
          <Tooltip title={user?.contact}>
            <Typography noWrap variant="caption">
              {user?.contact}
            </Typography>
          </Tooltip>
        </Box>
      </Box>

      <Box>
        <Badge
          badgeContent={data.count_unread_messages}
          color="error"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {data.is_active ? (
            <MailIcon color="action" />
          ) : (
            <Tooltip title="deleted">
              <PersonRemoveIcon />
            </Tooltip>
          )}
        </Badge>
      </Box>
    </Paper>
  );
};

export default ThreadItem;
