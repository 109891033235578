import React, { useState } from "react";
import { Badge, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useTheme } from "@mui/material/styles";
import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

import {
  StyledAppBar,
  StyledDrawer,
  StyledDrawerHeader,
  StyledListItemText,
} from "./NavMenu.styled";

import useGlobalCounters from "../../hooks/useGlobalCounters";
import AdminInfo from "./AdminInfo";
import BreadCrumbs from "../BreadCrumbs";

const NavMenu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const { supportCounter } = useGlobalCounters();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <StyledAppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" noWrap>
              Hubmee
            </Typography>
            <Typography variant="body1" noWrap>
              Administration dashboard
            </Typography>

            <BreadCrumbs />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
            }}
          >
            <AdminInfo />
          </Box>
        </Toolbar>
      </StyledAppBar>

      <StyledDrawer variant="permanent" open={open}>
        <StyledDrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </StyledDrawerHeader>

        <Divider />
        <List>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <StyledListItemText primary="Home" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/posts">
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <StyledListItemText primary="Post" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/analytics">
            <ListItemIcon>
              <AnalyticsOutlinedIcon />
            </ListItemIcon>
            <StyledListItemText primary="Analytic" />
          </ListItem>
          <ListItem button component={Link} to="/onboarding-data">
            <ListItemIcon>
              <AutoGraphIcon />
            </ListItemIcon>
            <StyledListItemText primary="Onboarding data" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <StyledListItemText primary="Users" />
          </ListItem>
          <ListItem button component={Link} to="/subscriptions">
            <ListItemIcon>
              <PeopleOutlineIcon />
            </ListItemIcon>
            <StyledListItemText primary="Subscriptions" />
          </ListItem>

          <ListItem button component={Link} to="/feedback">
            <ListItemIcon>
              <FeedbackOutlinedIcon />
            </ListItemIcon>
            <StyledListItemText primary="Feedback" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/chat_update">
            <ListItemIcon>
              <BrowserUpdatedIcon />
            </ListItemIcon>
            <StyledListItemText primary="Update chat" />
          </ListItem>
          <ListItem button component={Link} to="/chat_support">
            <ListItemIcon>
              <Badge
                badgeContent={supportCounter}
                color="error"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <SupportAgentIcon />
              </Badge>
            </ListItemIcon>

            <StyledListItemText primary="Support" />
          </ListItem>
        </List>
      </StyledDrawer>
    </Box>
  );
};

export default NavMenu;
