import React, { FC, useState } from "react";
import { Box, Button, Collapse, Input, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useApi } from "../../hooks/useApi";

type File = {
  created_at: string;
  ext: string;
  id: number;
  isImage: number;
  name: string;
  parent_id: null;
  size: number;
  token: string;
  type: string;
  updated_at: string;
  url: string;
  user_id: null;
};

type PhotoProps = {
  onChange: (file: File | null) => void;
  imageToken?: string;
};

const Photo: FC<PhotoProps> = ({ onChange, imageToken = "" }) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", fileList[0], fileList[0].name);
      const res = await api.post("/api/common/files?type=blog", formData);
      onChange(res.data);
      enqueueSnackbar("File successful added", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography>Background photo</Typography>
      <Box sx={{ marginTop: "20px" }}>
        {imageToken ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => onChange(null)}
          >
            Delete
          </Button>
        ) : (
          <label htmlFor="icon-button-file">
            <Input
              onChange={uploadPhoto}
              sx={{ display: "none" }}
              id="icon-button-file"
              type="file"
            />
            <LoadingButton
              loading={loading}
              variant="outlined"
              component="span"
            >
              Upload
            </LoadingButton>
          </label>
        )}
      </Box>
      <Collapse in={!!imageToken} mountOnEnter unmountOnExit>
        <Box
          sx={{
            maxWidth: "500px",
            marginTop: "20px",
          }}
        >
          <img
            style={{ borderRadius: "5px" }}
            src={`${process.env.REACT_APP_API_HOST}/assets/blog/${imageToken[0]}/${imageToken[1]}/${imageToken[2]}/${imageToken}/tiny.jpg`}
            alt="img"
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default Photo;
